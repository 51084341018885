// import { CustomWidgetCollection, Serializer, type Question, SvgRegistry } from "survey-core";
// import intlTelInput from 'intl-tel-input';
// import 'intl-tel-input/build/css/intlTelInput.css';
// import CountriesList from '../../assets/json/countries.json'

// SvgRegistry.registerIconFromSvg("icon-phone", `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
// `);

// function setupIntlTelInput(question: Question, el: any, reset = false) {
//     if (reset && question.itl) {
//         question.itl.destroy(); // Clean up the previous instance before setting up a new one
//     }

//     const input = el.querySelector('input');


//     if (input) {
//         if (question.readOnly || question.isReadOnly) {
//             input.setAttribute('readonly', question.isReadOnly);
//             input.setAttribute('disabled', question.isReadOnly);
//             input.classList.add('sd-input--disabled')
//         } else {
//             input.removeAttribute('readonly');
//             input.removeAttribute('disabled');
//             input.classList.remove('sd-input--disabled')
//         }

//         const placeholder = question.placeholder || 'Enter phone number'

//         question.itl = (<any>intlTelInput)(input, {
//             initialCountry: question.defaultCountry || "auto",
//             disabled: question.isReadOnly,
//             geoIpLookup: (callback: any) => {
//                 fetch("https://ipapi.co/json")
//                     .then((res) => res.json())
//                     .then((data) => callback(data.country_code))
//                     .catch(() => callback("us"));
//             },
//             utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
//             customPlaceholder: () => placeholder,
//         });

//         input.setAttribute('placeholder', placeholder);
//         input.value = question.value || '';

//         input.addEventListener('change', () => {
//             const fullNumber = question.itl.getNumber();
//             question.value = fullNumber;
//         });
//     }
// }

// let timeout: any;

// CustomWidgetCollection.Instance.add({
//     name: "phonenumber",
//     title: "Phone Number",
//     iconName: 'icon-phone',
//     htmlTemplate: `<input type="tel" class="sd-input sd-text custom_widget_phone"/>`,
//     widgetIsLoaded: () => true,
//     isFit: (question: Question) => question.getType() === "phonenumber",
//     init: () => {
//         Serializer.addClass("phonenumber", [], undefined, "empty");

//         Serializer.addProperty('phonenumber', {
//             name: "defaultCountry:dropdown",
//             category: 'data',
//             displayName: 'Default Country',
//             choices: CountriesList.map((cl) => { return { value: cl.code, text: cl.name } })
//         });

//         Serializer.addProperty('phonenumber', {
//             name: "placeholder:string",
//             category: 'general',
//             displayName: 'Placeholder',
//             default: "Enter phone number"
//         });
//     },
//     afterRender: (question: Question, el: any) => {
//         question.onPropertyChanged.add((sender, options) => {
//             if (options.name === 'defaultCountry' || options.name === 'placeholder' || options.name == 'readOnly' || options.name == 'isReadOnly') {
//                 if (timeout) clearTimeout(timeout);
//                 timeout = setTimeout(() => {
//                     setupIntlTelInput(question, el, true);
//                     timeout = null;
//                 }, 300);
//             }
//         });
//         setupIntlTelInput(question, el);
//     },
//     willUnmount: (question: Question, el: any) => {
//         if (question.itl) {
//             question.itl.destroy();
//         }
//     }
// }, "customtype");


import { ComponentCollection, Serializer, type Question, SvgRegistry } from "survey-core";
import CountriesList from '../../assets/json/countries.json'
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

SvgRegistry.registerIconFromSvg("icon-phone", `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
`);

let timeout: any;
(<any>ComponentCollection).Instance.add({
  name: "phonenumber",
  title: "Phone Number",
  defaultQuestionTitle: "Phone Number",
  iconName: 'icon-phone',
  questionJSON: {
    type: "text",
   "inputType": "tel",
   placeholder: "Enter phone number"
  },
  onInit: () => {
    Serializer.addProperty('phonenumber', {
           name: "defaultCountry:dropdown",
           category: 'data',
           displayName: 'Default Country',
           choices: (obj: any, choicesCallback: any) => {
             const choices = CountriesList.map((cl: any) => { return { value: cl.code, text: cl.name }});
             choicesCallback(choices);
           }
       });

       Serializer.addProperty('phonenumber', {
            name: "placeholder:string",
            category: 'general',
            displayName: 'Placeholder',
            default: "Enter phone number"
        });
   },
  //  inheritBaseProps: [ "readOnly"],
  //  onLoaded(question: any) {
  //   if (timeout) clearTimeout(timeout);
  //       timeout = setTimeout(() => {
  //           timeout = null;
  //           this.handleCountrychange(question);
  //       }, 10);
  //  },

   onAfterRender(question: any, htmlElement: any) {
    this.handleCountrychange(question);
   },

   onPropertyChanged(question: any, propertyName: any, newValue: any) {
     if (propertyName === "defaultCountry" || propertyName === 'placeholder' || propertyName == 'readOnly' || propertyName == 'isReadOnly') {

      if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
        this.handleCountrychange(question, true);
        timeout = null;
    }, 300);
   }
 },
 handleCountrychange(question: any, reset = false) {
    if (reset && question.itl) {
         question.itl.destroy(); // Clean up the previous instance before setting up a new one
    }
    
    
    const PhoneElement = document.querySelector(`#${question.id}`);
    const inputElement = PhoneElement?.querySelector('input');
    const placeholder = question.placeholder;

   // Handle the 'defaultCountry' property change as needed
   if(inputElement) {   
       question.itl = (<any>intlTelInput)(inputElement, {
        initialCountry: question.defaultCountry || "auto",
        disabled: question.isReadOnly,
        geoIpLookup: (callback: any) => {
            fetch("https://ipapi.co/json")
                .then((res) => res.json())
                .then((data) => callback(data.country_code))
                .catch(() => callback("us"));
        },
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        customPlaceholder: () => question.placeholder,
    });

      inputElement.setAttribute('placeholder', placeholder);
      inputElement.value = question.value || '';
      inputElement.addEventListener('change', () => {
          const fullNumber = question.itl.getNumber();
          question.value = fullNumber;
      });

      if (question.readOnly || question.isReadOnly) {
        inputElement.setAttribute('readonly', question.isReadOnly);
        inputElement.setAttribute('disabled', question.isReadOnly);
        inputElement.classList.add('sd-input--disabled')
      } else {
        inputElement.removeAttribute('readonly');
        inputElement.removeAttribute('disabled');
        inputElement.classList.remove('sd-input--disabled')
      }
   }
 },
})