import { localization } from "survey-creator-core";

const translations = localization.getLocale("");

translations.ed.surveyPlaceHolder = 'The form is empty. Drag an element from the left panel or click the button below.';
translations.ed.designer = 'Design'
translations.ed.addNewQuestion = 'Add Element'
translations.ed.newNewQuestion = 'field'
translations.ed.panelPlaceHolder = 'Drop an element from the panel here.'
translations.ed.questionTypeName = 'Field'
translations.ed.saveSurvey = 'Save Form'
translations.ed.survey = 'Form'
translations.ed.saveSurveyTooltip = 'Save Form'
translations.qt.text = 'Short Text'
translations.qt.radiogroup = 'Radio Buttons'
translations.pe.locale = 'Form language'
translations.pe.name = 'Field Name'
translations.pe.title = 'Question / Label Text'
translations.pe.showPreviewBeforeComplete = 'Preview answers before submitting the form'
translations.pe.completeText = 'Complete Form button text'
translations.pe.startSurveyText = 'Start Form button text'
translations.pe.tabs.logo = 'Logo in Form Header'
translations.pe.tabs.navigation = 'Button'
translations.pe.tabs.choicesByUrl = 'Dynamic Choices List'
translations.pe.tabs.showOnCompleted = 'Form Complete'
translations.survey.addLogicItem = "Create a rule to customize the flow of the form.";
translations.ed.surveySettings = "Form Settings";
translations.ed.surveySettingsTooltip = "Open form settings";
translations.ed.testSurveyAgain = "Preview Form Again";
translations.ed.testSurveyWidth = "Form width: ";
translations.ed.embedSurvey = "Embed Form";
translations.ed.surveyResults = "Form Results ";
translations.ed.lg.logicPlaceholderDescription = "Create a rule to customize the flow of the form.";
translations.ed.lg.trigger_completeName = "Complete form";
translations.ed.lg.completedHtmlOnConditionName = "Set \"Form Complete\" page markup";
translations.ed.lg.trigger_completeDescription = "When the logic expression returns true then the form becomes completed and an end-user see the 'Thank you page'.";
translations.ed.lg.trigger_skipDescription = "When the logic expression returns true then the form skip to / focus the selected question.";
translations.ed.lg.trigger_completeText = "form becomes completed";
translations.ed.lg.trigger_skipText = "form skip to the question {0}";
translations.ed.lg.trigger_runExpressionDescription = "When the logical expression evaluates to true, the form evaluates another expression. Optionally, the result of the latter expression can be assigned as a value to the selected question"

translations.pe.showPreviewBeforeComplete = "Preview answers before submitting the form";
translations.pe.surveyTitlePlaceholder = "Form Title";
translations.pe.surveyEditorTitle = "Edit form settings";
translations.pe.includeIntoResult = "Include into form results";
translations.pe.locale = "Form language";
translations.pe.sendResultOnPageNext = "Save partial form results in progress";
translations.pe.completeText = "Complete Form button text";
translations.pe.startSurveyText = "Start Form button text";
translations.pe.showCompletedPage = "Show the Form Complete page";
translations.pe.maxTimeToFinish = "Time limit to finish the form (in seconds)";
translations.pe.questionsOnPageMode = "Form structure";
translations.pe.completedBeforeHtml = "Markup to show if the user already filled out this form";
translations.pe.completedHtml = "Form Complete page markup";
translations.pe.completedHtmlOnCondition = "Dynamic Form Complete page markup";
translations.pe.loadingHtml = "Markup to show while form model is loading";
translations.pe.tabs.showOnCompleted = "Form Complete";
translations.pe.tabs.logo = "Logo in Form Header";
translations.pe.noquestions = "There are no questions in the form.";
translations.pe.triggerCompleteText = "Complete the form if succeed.";
translations.pe.triggerIsVariable = "Do not put the variable into the form result.";
translations.pe.survey.title = "Form Title";
translations.pe.survey.description = "Form Description";
translations.pe.masksettings.saveMaskedValue = "Save masked value in form results";
translations.pe.allowCompleteSurveyAutomatic = "Complete the form automatically";
translations.pe.logo = "Form logo";
translations.pe.mode = "Form display mode";


translations.pv.onSurvey = "Continue throughout the form";
translations.pv.onComplete = "When the form is completed";
translations.pv.survey = "Form";
translations.pv.showQuestionNumbers.onSurvey = "Continue across the form";

translations.ew.showOnPage = "Show form on a page";
translations.ew.showInWindow = "Show form in a window";
translations.ew.loadFromServer = "Load Form JSON from server";
translations.triggers.completetrigger = "Complete form";
translations.pehelp.cookieName = "Cookies prevent users from filling out the same form twice.";
translations.pehelp.keyName = "If the specified column contains identical values, the form throws the \"Non-unique key value\" error.";

translations.p.surveyId = "Form id";
translations.p.surveyPostId = "Form post id";
translations.p.surveyShowDataSaving = "Form show data saving";
translations.p.widthMode = "Form width mode"

translations.theme.surveyTitle = "Form title font";
translations.theme.surveyDescription = "Form description font";
translations.theme.coverInheritWidthFromSurvey = "Same as form";